import React, { useState, useEffect } from 'react'
import { useTransition, animated, config, easings} from '@react-spring/web'
//import styles from './styles.module.css'

const BackgroundPanel = ({backgroundArr, counter}) =>{
//export default function BackgroundPanel({bkgd}) {
  const [index, set] = useState(counter)
  const [show, setShow] = useState(false)
  
 // const [backImg, setBackImg] = useState(bkgd)
  const transitions = useTransition(index, {
    key: index,
    from: { 
        opacity: 0,
        scale: 0.99
    },
    enter: { 
        opacity: 1,
        scale:1
     },
    leave: { 
        opacity: 0,
        scale: 0.99
     },
   // config: { duration: 600 },
   config: {
       duration: 500,
      easing: easings.easeInOutBack,
   },
 //   config: config.gentle,
   // onRest: (_a, _b, item) => {
     // if (index === item) {
       //  set(state => (state + 1) % slides.length)
     // }
  //  },
    exitBeforeEnter: true,
  })

  useEffect(() => {
    set(counter)
 }, [counter])


 
/*
 useEffect(() => {
    if(gameState==='intro'){
        setShow(false)
    }else{
        setShow(true)
    }
 }, [gameState])
*/
/*
  useEffect(() => {
    console.log('bkgd', bkgd)
    setBackImg(bkgd)
    }, [bkgd])
*/

  return (
    <div className="flex fill center background" 

    style={{
      height: '500px',
     // background: (counter===0) ? 'transparent' : 'white'
    }}>
{/*
      <img
        className="wpb"
        src="/assets/images/waypastbooks-tag-com2.png"
        alt=""
        width="400"
      />
  */}
      {transitions((style, i) => (
        <animated.div
          className="bg"
          style={{
            ...style,
           backgroundImage:`url(${backgroundArr[i].url})`,
          }}
        />
      ))}

    </div>
  )
}

//export default React.memo(BackgroundPanel);
export default BackgroundPanel;
