import React, { useCallback, useEffect, useState, useRef } from 'react'
//import { render } from 'react-dom'
import { PopoverPicker } from "../components/PopoverPicker";
import Slider from '@mui/material/Slider';
import { FaBeer } from 'react-icons/fa';
import { BsFillCameraFill, BsBrush} from "react-icons/bs";
import { GrEdit, GrErase } from "react-icons/gr";
import { MdDelete, MdArrowForward, MdArrowBack } from "react-icons/md";
import { FaRegImage } from "react-icons/fa";

import { AiOutlineUndo, AiOutlineRedo } from "react-icons/ai";

import { useMediaQuery } from 'react-responsive'

import { HexColorPicker } from "react-colorful";

import useClickOutside from "../useClickOutside";

import { 
IoArrowRedo,
IoArrowUndo
} from "react-icons/io5";
export default function ControlBar({showBug=true, color, goUndo, goRedo, usePencil, useEraser, useBrush, setColor,nextBkgd, prevBkgd, clearDrawing, exportAsPicture, value, sliderChange, openX, setOpen, sliderState, btnSound}) {
  const popover = useRef();
  
  const [open, set] = useState(showBug)
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);



  const isMobile = useMediaQuery({ query: '(max-width: 580px)' })

  useEffect(() => {

  }, [])

  return (

    <>

    <div className="ControlBar" >
      <div className="bkgdBar">
          <div className="button-col">

              <button onClick={nextBkgd} onMouseEnter={btnSound} ><MdArrowForward /></button>
              <button onClick={prevBkgd} onMouseEnter={btnSound}><MdArrowBack /></button>
             
          </div>
      </div>
      <div className="drawBar">
          <div className="button-col">
              {/*  
              
              <button  className="stroke-value"
              onClick={() => setOpen(!openX)}>{value}</button>
            
              <button onClick={() => setColor("#000000")}><GrEdit /></button>
              <button onClick={() => setColor("#FFFFFF")}><GrErase /></button>
          */}
              
              <button onClick={usePencil} onMouseEnter={btnSound}><GrEdit /></button>
             
              <button onClick={useBrush} onMouseEnter={btnSound}><BsBrush /></button>
             
              <button onClick={useEraser} onMouseEnter={btnSound}><GrErase /></button>
          {/*   <button onClick={nextBkgd}><FaRegImage  /></button> */}
              <button onClick={goUndo} onMouseEnter={btnSound}><IoArrowUndo /></button>
              <button onClick={goRedo} onMouseEnter={btnSound}><IoArrowRedo /></button>
              <button onClick={clearDrawing} onMouseEnter={btnSound}><MdDelete /></button>
          {/*   <button onClick={exportAsPicture}><BsFillCameraFill /></button> */}
              
          </div>
      </div>
      
      <div className={sliderState ? "slider-col" : "slider-col hide-slider"}>

        <div className="color-holder">

          <div className="size"
           onClick={(e) => { 
            //  console.log(e)
              const popPos = e;
              console.log('pop', popPos)
            }}
            >{value}</div>

           <PopoverPicker 
                color={color} 
                toggle={toggle}
               
              // onChange={setColor}
                onChange={(e) => { 
                //  console.log(e)

                  setColor(e) 
                }}
                />
                  </div>
        {/*
          <button  className="stroke-value"
              onClick={() => setOpen(!openX)}>{value}</button>

              */}

        {isMobile ?
              <Slider 
                sx={{
                  '& input[type="range"]': {
                    WebkitAppearance: 'slider-horizontal',
                  },
                }}
                size="small"
                value={value} 
            //   onChange={sliderChange} 
                onChange={(e) => { 
                  //console.log(e)
                  sliderChange(e) 
                }}
                aria-label="Default" 
              //  valueLabelDisplay="auto" 
                min={1} max={50} orientation="horizontal"/>

          :

              <Slider 
              sx={{
                '& input[type="range"]': {
                  WebkitAppearance: 'slider-vertical',
                },
              }}
              size="small"
              value={value} 
          //   onChange={sliderChange} 
              onChange={(e) => { 
                //console.log(e)
                sliderChange(e) 
              }}
              aria-label="Default" 
              valueLabelDisplay="auto" min={1} max={50} orientation="vertical"/>
            }
        
        </div>

       
      
    </div>

        {isOpen && (
              <div className="popover" ref={popover}>
                <HexColorPicker color={color} 
              // onChange={onChange} 
                onChange={(e) => {  setColor(e)  }}
                
                /> 
              </div>
            )}

    </>


  )
}