const audioMap = {
  socute: '/assets/audio/Bink-Aww-those-dogs-are-so-cute.m4a',
  changebkgd: '/assets/audio/Bink-Click-Here-to-Change-the-Background.m4a',
  clicktodraw: '/assets/audio/Bink-Click-Here-to-Draw-Your-Dog.m4a',
  grr: '/assets/audio/Bink-Grrr.m4a',
  playagain: '/assets/audio/Bink-Play-Again.m4a',
  puppies: '/assets/audio/Bink-Puppies.m4a',
  ruff: '/assets/audio/Bink-Ruff-Ruff.m4a',
 // send: '/assets/audio/Bink-Send-it-to-a-Friend.m4a',
  clicktosave: '/assets/audio/JA-Click-Here-to-Save-Your-Drawing.m4a',
  timetodraw: '/assets/audio/JA-Its-time-to-draw-your-own-dogs.m4a',
  pickbkgd: '/assets/audio/JA-pick-a-background-draw-your-dogs-and-save.m4a',
  save: '/assets/audio/JA-Save-your-Drawing.m4a',
  cute: '/assets/audio/SL-Aww-Isnt-that-dog-cute.m4a',
  clicktoplayagain: '/assets/audio/SL-Click-here-to-play-again.m4a',
  everyone: '/assets/audio/SL-Puppies-for-everyone.m4a',
  song: '/assets/audio/little-story-tobias-voigt-main-version-19302-02-06-edit.mp3',
  click: '/assets/audio/683098__florianreichelt__click.mp3',
  whoosh: 'assets/audio/Woosh-B4-www.fesliyanstudios.com.mp3'
  // click: '/assets/audio/click-002.m4a',
 // click:'/assets/audio/Mouse_Click_1-fesliyanstudios.com.mp3',

}

export default audioMap


//socute
//cute
//grr
//puppies
//everyone