import React, { useCallback, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";

import useClickOutside from "../useClickOutside";

export const PopoverPicker = ({ color, onChange, toggle }) => {
 // const popover = useRef();
  //const [isOpen, toggle] = useState(false);

  //const close = useCallback(() => toggle(false), []);
  //useClickOutside(popover, close);

  return (
    <div className="picker">
      <div
        className="swatch"
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      >


<div className="use-rainbow__swatches">
      <div style={{ backgroundColor: 'hsl(0, 90%, 60%)'}}></div>
    {/*  <div style={{ backgroundColor: 'hsl(24, 90%, 60%)'}}></div> */}
      <div style={{ backgroundColor: 'hsl(48, 90%, 60%)'}}></div>
    {/*  <div style={{ backgroundColor: 'hsl(72, 90%, 60%)'}}></div>*/}
      <div style={{ backgroundColor: 'hsl(96, 90%, 60%)'}}></div>
    {/*  <div style={{ backgroundColor: 'hsl(120, 90%, 60%)'}}></div>*/}
      <div style={{ backgroundColor: 'hsl(144, 90%, 60%)'}}></div>
   {/*   <div style={{ backgroundColor: 'hsl(168, 90%, 60%)'}}></div>*/}
      <div style={{ backgroundColor: 'hsl(192, 90%, 60%)'}}></div>
   {/*   <div style={{ backgroundColor: 'hsl(216, 90%, 60%)'}}></div>*/}
      <div style={{ backgroundColor: 'hsl(240, 90%, 60%)'}}></div>
   {/*   <div style={{ backgroundColor: 'hsl(264, 90%, 60%)'}}></div>*/}
      <div style={{ backgroundColor: 'hsl(288, 90%, 60%)'}}></div>
    {/*  <div style={{ backgroundColor: 'hsl(312, 90%, 60%)'}}></div>*/}
      <div style={{ backgroundColor: 'hsl(336, 90%, 60%)'}}></div>
    </div>

        </div>

      {/* isOpen && (
        <div className="popover" ref={popover}>
          <HexColorPicker color={color} 
         // onChange={onChange} 
          onChange={(e) => { onChange(e) }}
          
          />
        </div>
      ) */}
    </div>
  );
};
