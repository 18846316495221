import React, { useEffect, useRef, forwardRef, useMemo, useState   } from 'react';
import {
  CanvasPath,
  ExportImageType,
  ReactSketchCanvas,
  ReactSketchCanvasProps,
  ReactSketchCanvasRef,
} from 'react-sketch-canvas';
import html2canvas from 'html2canvas'
import { PopoverPicker } from "./components/PopoverPicker";
import { useTrail, animated as a } from "react-spring";
import { HiSpeakerWave, HiSpeakerXMark  } from "react-icons/hi2";
import useSound from 'use-sound';

import audioMap from './resources/audio';
import { isMobile } from 'react-device-detect';



import ControlBar from './components/ControlBar';
import MenuBar from './components/MenuBar';
import LogoBug from './components/LogoBug';
//import AudioProvider from './components/AudioProvider';
import BackgroundPanel from './components/BackgroundPanel';

import StepOne from './assets/images/step1.svg'
import StepTwo from './assets/images/step2.svg'
import StepThree from './assets/images/step3.svg'
import StepFour from './assets/images/step4.svg'
import StepFive from './assets/images/step5.svg'

//import { useMediaQuery } from 'react-responsive'
//import { mediaQueries } from './config'

import "./styles.scss";


//onst introPhrase = ["Choose", "a", "background,", "draw", "and", "save", "your", "dogs"];

const introPhrase = ["Pick a background," , "draw your dogs,", "and save!"];
const config = { mass: 5, tension: 2000, friction: 200 };


const backgroundArr = [

  {
    type: 'image',
    url: '/assets/backgrounds/graphpaper.jpg',
  },
  {
    type: 'image',
    url: '/assets/backgrounds/Dog-Background-1.jpg',
  },
  {
    type: 'image',
    url: '/assets/backgrounds/Dog-Background-2.jpg',
  },
  {
    type: 'image',
    url: '/assets/backgrounds/Dog-Background-3.jpg',
  },
  {
    type: 'image',
    url: '/assets/backgrounds/Dog-Background-4.jpg',
  },
  {
    type: 'image',
    url: '/assets/backgrounds/Dog-Background-5.jpg',
  },

  {
    type: 'image',
    url: '/assets/backgrounds/Dog-Background-6.jpg',
  },
    /*
 {
   type: 'image',
   url: '/assets/backgrounds/jealous-neighborhood.jpg',
 },
 {
  type: 'image',
  url: '/assets/backgrounds/lonely-houses.jpg',
},
*/
  /*
 {
   type: 'image',
   url: '/assets/backgrounds/mad-neighborhood2.jpg',
 },

 {
   type: 'image',
   url: '/assets/backgrounds/mad-room.jpg',
 },

 {
   type: 'image',
   url: '/assets/backgrounds/strongest-school.jpg',
 },
 {
   type: 'image',
   url: '/assets/backgrounds/worried-party.jpg',
 },

 {
   type: 'image',
   url: '/assets/backgrounds/worried-party2.jpg',
 },

   {
     type: 'image',
     url: '/assets/backgrounds/afraid-storm.jpg',
   },
   {
     type: 'image',
     url: '/assets/backgrounds/afraid-sunny.jpg',
   },
   */
  /*
   {
     type: 'image',
     url: '/assets/backgrounds/lonely-ducks.jpg',
   },
   {
     type: 'image',
     url: '/assets/backgrounds/lonely-porch.jpg',
   },
   {
    type: 'image',
    url: '/assets/backgrounds/lonely-land.jpg',
  },
  */
   /*
   {
     type: 'image',
     url: '/assets/backgrounds/lonely-neighborhood.jpg',
   },
   
   {
     type: 'image',
     url: '/assets/backgrounds/lonely-playground.jpg',
   },
   
   {
     type: 'image',
     url: '/assets/backgrounds/mad-crowd.jpg',
   },
  
   {
     type: 'image',
     url: '/assets/backgrounds/mad-house.jpg',
   },
    */
 
  
 ]
 
/*
type Handlers = [string, () => void, string][];

interface InputFieldProps {
  fieldName: keyof ReactSketchCanvasProps;
  type?: string;
  canvasProps
  setCanvasProps
  >;
}
*/
function InputField({
  fieldName,
  type = 'text',
  canvasProps,
  setCanvasProps,
}) {
  const handleChange = ({
    target,
  }): void => {
    setCanvasProps((prevCanvasProps) => ({
      ...prevCanvasProps,
      [fieldName]: target.value,
    }));
  };

  const id = 'validation' + fieldName;

  return (
    <div className="p-2 col-10">
      <label htmlFor={id} className="form-label">
        {fieldName}
      </label>
      <input
        name={fieldName}
        type={type}
        className="form-control"
        id={id}
        value={canvasProps[fieldName]}
        onChange={handleChange}
        min={1}
        max={30}
      />
    </div>
  );
}

function App() {
  const [canvasProps, setCanvasProps] = React.useState({
    className: 'react-sketch-canvas',
    width: '100%',
    height: '500px',
  //  backgroundImage: backgroundArr[3].url,
  //  backgroundImage: '/assets/backgrounds/afraid-storm.jpg',
   // backgroundImage:'https://upload.wikimedia.org/wikipedia/commons/7/70/Graph_paper_scan_1600x1000_%286509259561%29.jpg',
    
  backgroundPosition: 'center',
 // backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
 // backgroundColor: 'red',
  preserveBackgroundImageAspectRatio: 'none',
    strokeWidth: 4,
    eraserWidth: 5,
    strokeColor: '#000000',
    canvasColor: 'none',
    paintMode: 'false',
    style: { 
     // borderRight: '1px solid #CCC' 
      border: 'none'
    },
    exportWithBackgroundImage: false,//true,
    withTimestamp: true,
    allowOnlyPointerType: 'all',
  });

  const [counter, setCounter] = React.useState(0)
  const [ready, setReady] = React.useState(false);
  const [titleActive, setTitleActive] = useState(false);
  const [gameState, setGameState] = useState('intro');
  const [bkgdSeen, setBkgdSeen] = useState(false);
  const [bkgdPhase, setBkgdPhase] = useState(false);
  const [sliderState, setSliderState] = useState(false);
  const [audioState, setAudioState] = useState(false);
  const [gameInit, setGameInit] = useState(false);

  const [activeAudio, setActiveAudio] = useState(audioMap.grr);

  const [playWhoosh] = useSound(audioMap.whoosh, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });

  const [playSocute] = useSound(audioMap.socute, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });
  const [playChangebkgd] = useSound(audioMap.changebkgd, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });
  const [playClicktodraw] = useSound(audioMap.clicktodraw, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });
  const [playGrr] = useSound(audioMap.grr, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });

  const [playPlayagain] = useSound(audioMap.playagain, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });
  const [playPuppies] = useSound(audioMap.puppies, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });
  const [playRuff] = useSound(audioMap.ruff, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });
  const [playSend] = useSound(audioMap.send, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });
  const [playClicktosave] = useSound(audioMap.clicktosave, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });
  const [playTimetodraw] = useSound(audioMap.timetodraw, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });
  const [playPickbkgd] = useSound(audioMap.pickbkgd, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });
  


  const [playSave, {saveSound}] = useSound(audioMap.save, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });

  const [playCute] = useSound(audioMap.cute, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });

  const [playClicktoplayagain, {againSound}] = useSound(audioMap.clicktoplayagain, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });

  const [playEveryone] = useSound(audioMap.everyone, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,

  });

  const [playSong] = useSound(audioMap.song, {
    volume: (audioState) ? 0.2 : 0,
    loop: true
   // interrupt: true,
  });

  const [playbackRate, setPlaybackRate] = useState(0.75);

  const [playClickSFX] = useSound('/assets/audio/click-002.m4a', {
    volume: (audioState) ? 0.1 : 0,
    playbackRate: playbackRate
   // interrupt: true,
  });


 
 
 

/*
  const isMobile = useMediaQuery({ query: mediaQueries.mobile })
  const isTablet = useMediaQuery({ query: mediaQueries.tablet })
  const isDesktop = useMediaQuery({ query: mediaQueries.desktop })
*/
  
  const intervalRef = useRef();

  const activateClick = () =>{
    if (audioState){
      setPlaybackRate(0.75 + Math.random()*2)
      playClickSFX();
    }
  }
  
  const getStarted = () => {
      setGameState('backgroundMode')
      if (!gameInit) {
        playSong();
        setGameInit(true);
      }
      setAudioState(true);
      
     

  }

  useEffect(() => {

    let timer;
    let interval;
    clearTimeout(timer);

    if (bkgdPhase){
      setCounter(1)
      setBkgdSeen(true)


        interval = setInterval(() => {
            
          const rand = Math.floor(Math.random()*(backgroundArr.length-1));
          setCounter(rand)
        }, 1200);

        intervalRef.current = interval;

        timer = setTimeout(() => {
          
          clearInterval(interval);
          document.body.classList.add('hide-intro');
        // setCounter(0)
        }, 3600);
  }

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
 
    }

  }, [bkgdPhase])


  useEffect(() => {
 
    let voiceCount;
    let introVoice;
   
    if (gameState === 'intro'){
       console.log('intro')
      //  setTitleActive(true);
      document.body.classList.add('reveal-steps');
     /*
      timer = setTimeout(() => {
        setGameState('backgroundMode')
        playpickbkgd()
        alert('play')
      }, 3500);
*/
      setCounter(0)
      setBkgdSeen(false)
      setBkgdPhase(false)
      resetClasses();
      document.body.classList.add('mode-intro');


   

    }else if (gameState === 'backgroundMode'){
      console.log('backgroundMode')

      

      if (!bkgdSeen){
       

        introVoice = setTimeout(() => {
          playTimetodraw();
        
        }, 100);


        voiceCount = setTimeout(() => {
          playPickbkgd();
          setBkgdPhase(true)
        
        }, 3000);

       
      }else{
        resetClasses();
        document.body.classList.add('hide-intro');
      }

      playWhoosh();


      document.body.classList.add('mode-background');

    }else if (gameState === 'drawingMode'){
      playWhoosh();
      console.log('drawingMode')
      if (bkgdSeen) clearInterval(intervalRef.current); 
      resetClasses();
      document.body.classList.add('mode-drawing');

    }else if (gameState === 'snapshotMode'){
      resetClasses();

      document.body.classList.add('mode-snapshot');
    
    }
    return () => {
   
      clearTimeout(introVoice);
      clearTimeout(voiceCount);
    }
  }, [gameState])

  const resetClasses = () =>{
    if (document.body.classList.contains('mode-intro')) {
      document.body.classList.remove('mode-intro');
    }

    if (document.body.classList.contains('hide-intro')) {
      document.body.classList.remove('hide-intro');
    }

    if (document.body.classList.contains('mode-background')) {
      document.body.classList.remove('mode-background');
    }

    if (document.body.classList.contains('mode-drawing')) {
      document.body.classList.remove('mode-drawing');
    }

    if (document.body.classList.contains('mode-snapshot')) {
      document.body.classList.remove('mode-snapshot');
    }

    if (document.body.classList.contains('bg-tag')) {
      document.body.classList.remove('bg-tag');
    }
  }

  const [scrolledToSteps, setScrolledToSteps] = useState(0);
  const [toggle, set] = useState(true);
  const trail = useTrail(introPhrase.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    height: toggle ? 40 : 0,
    from: { opacity: 0, x: 20, height: 0 }
  });

/*
  const [exportProps, setExportProps] = React.useState({
    width: '100%',
    height: '500px',
    backgroundImage: '/assets/backgrounds/afraid-storm.jpg',
 
  });
*/
  const inputProps = [
    ['className', 'text'],
    ['width', 'text'],
    ['height', 'text'],
    ['backgroundImage', 'text'],
    ['preserveBackgroundImageAspectRatio', 'text'],
    ['strokeWidth', 'number'],
    ['eraserWidth', 'number'],
  ];

  const doneMessageArr = [
    'socute',
    'cute',
    'ruff',
    'puppies',
    'everyone'
  ]

  const canvasRef = React.createRef();

  const [dataURI, setDataURI] = React.useState('');
  const [svg, setSVG] = React.useState('');
  const [paths, setPaths] = React.useState([]);
  const [lastStroke, setLastStroke] = React.useState({ stroke: null, isEraser: null });
  const [pathsToLoad, setPathsToLoad] = React.useState('');
  const [sketchingTime, setSketchingTime] = React.useState(0);
  const [exportImageType, setexportImageType] =
    React.useState('png');

  const imageExportHandler = async () => {
    const exportImage = canvasRef.current?.exportImage;

    if (exportImage) {
      const exportedDataURI = await exportImage(exportImageType);
      setDataURI(exportedDataURI);
    }
  };

  const svgExportHandler = async () => {
    const exportSvg = canvasRef.current?.exportSvg;

    if (exportSvg) {
      const exportedDataURI = await exportSvg();
      setSVG(exportedDataURI);
    }
  };

  const getSketchingTimeHandler = async () => {
    const getSketchingTime = canvasRef.current?.getSketchingTime;

    try {
      if (getSketchingTime) {
        const currentSketchingTime = await getSketchingTime();
        setSketchingTime(currentSketchingTime);
      }
    } catch {
      setSketchingTime(0);
      console.error('With timestamp is disabled');
    }
  };

  const penHandler = () => {
   // playPlayagain();
   // playSocute();
  //  playChangebkgd();
 //   playClicktodraw();
   // playGrr()
   // playPlayagain();
  //  playPuppies();
  //  playRuff();
   // playSend();
   // playClicktosave();
   // playTimetodraw();
   // playPickbkgd();
   // playSave();
    //playCute();

   // playEveryone();
   // playClicktoplayagain()
  
   // playClickSFX();
  
    setSliderState(true);
    const eraseMode = canvasRef.current?.eraseMode;
  //  const paintMode = canvasRef.current?.paintMode;

    if (eraseMode) {
      eraseMode(false);
    }
/*
    if (paintMode) {
      //paintMode(false);
      canvasRef.current.paintMode = false
    }
    */
   

    setCanvasProps(
      (prevCanvasProps) => ({
        ...prevCanvasProps,
        strokeWidth: 4,
        strokeColor: '#000000',
        paintMode: false
      })
    );
  };

  const eraserHandler = () => {
    playClickSFX();
    setSliderState(true);
    const eraseMode = canvasRef.current?.eraseMode;

    if (eraseMode) {
      eraseMode(true);
      canvasRef.current.paintMode = false
    }
  };

  const brushHandler = () => {
    playClickSFX();
    setSliderState(true);
    const eraseMode = canvasRef.current?.eraseMode;
    //const paintMode = canvasRef.current?.paintMode;
    console.log('paintMode', canvasProps.paintMode)

    if (eraseMode) {
      eraseMode(false);
    }

   // if (paintMode) {
     // paintMode(true);
    //  canvasRef.current.paintMode = true
   // }

    setCanvasProps(
      (prevCanvasProps) => ({
        ...prevCanvasProps,
        strokeWidth: 30,
        strokeColor: '#ff0000E6',
        paintMode: true
      })
    );
  };

  const undoHandler = () => {
    playClickSFX();
    setSliderState(false);
    const undo = canvasRef.current?.undo;

    if (undo) {
      undo();
    }
  };

  const redoHandler = () => {
    playClickSFX();
    setSliderState(false);
    const redo = canvasRef.current?.redo;

    if (redo) {
      redo();
    }
  };

  const clearHandler = () => {
    playClickSFX();
    setSliderState(false);
    const clearCanvas = canvasRef.current?.clearCanvas;

    if (clearCanvas) {
      clearCanvas();
    }
  };

  const resetCanvasHandler = () => {
    playClickSFX();
    setSliderState(false);
    const resetCanvas = canvasRef.current?.resetCanvas;

    if (resetCanvas) {
      resetCanvas();
    }
  };
/*
  const exportAsPicture = () => {
  
    //alert('export')
    
     
     var htmlWidth = html.clientWidth
     var bodyWidth = body.clientWidth
   
   //  var data = document.getElementById('exportContainer')
     data = document.getElementById('threeCanvas')
   
     const canvas = document.querySelector('canvas');
     //var gl = canvas.getContext('webgl');
    // console.log('canvas gl', gl)
   
    // var gl = canvas.getContext('webgl');
   //gl.getContextAttributes();
     //var gl = canvas.getContext('webgl', { preserveDrawingBuffer: true });
   
     
  //   console.log('data', data)
    // console.log('data childs', data.children)
    // data.children[0].getContext('webgl' , {preserveDrawingBuffer: true});
   
   
     var newWidth = data.scrollWidth - data.clientWidth
   
     if (newWidth > data.clientWidth) {
       htmlWidth += newWidth
       bodyWidth += newWidth
     }
   
     html.style.width = htmlWidth + 'px'
     body.style.width = bodyWidth + 'px'

     setSnapActive(true);
     play();
     
   
     setTimeout( grabImageData(), 2000);
    
   }

   */

   const changeAudio = (msg) => {

    setActiveAudio(msg);

   }

   const changeToBackground = () =>{
 
    setSliderState(false);
    setGameState('backgroundMode')

   }

   const changeToDrawing = () =>{
    setSliderState(true);
/*
    if (isTablet || isDesktop) {
      if (scrolledToSteps <1) {
        window.scrollTo({top: 260, left: 0, behavior: 'smooth' });
        setScrolledToSteps(1)
      }
    }
    */
    setGameState('drawingMode')

  }

  const startOver =() =>{
    setGameState('intro')
    resetCanvasHandler();
    
  }

  ///////////////////////
  


  const grabImageData = () => {
    // console.log("---grab----");
    // console.log('data grab', data);

    //playSave();
       
    const data = document.getElementById('exportContainer')
    console.log('data grab', data);
    document.body.classList.add('bg-tag');
   /*
    canvasRef.current.exportImage("png")
                 .then(data => {
                   console.log(data);
                   saveAs(data, 'snapshot.png')
                 })
                 .catch(e => {
                   console.log(e);
                 });
                 */

      const imageBorder = 60;
      const msgWidth = 900;//465;
      const msgHeight = 80;//55;
      const msgRatio =msgHeight/ msgWidth;
      const halfWidth = msgWidth/2;
      const halfHeight = msgHeight/2;
   
      // html2canvas(data, {backgroundColor:null})
       html2canvas(data, {
         width: data.getBoundingClientRect().width + (imageBorder*2) + 10, //extra 10 ???
         height: data.getBoundingClientRect().height + (imageBorder*2),
         backgroundColor: 'transparent',
         x: imageBorder * -1,
         y: imageBorder * -1,
        // backgroundImage:canvasProps.backgroundImage,
       //  backgroundImage: GoldBackground.src
       })
         .then((canvas) => {
        //   console.log('canvas', canvas)

      
           var contextScreenshot = canvas.getContext('2d')
           console.log('contextScreenshot', contextScreenshot)

           var ctx = canvas.getContext('2d')
           const scale = window.devicePixelRatio;
           

           var backCanvas = document.createElement('canvas');
          backCanvas.width = canvas.width;
          backCanvas.height = canvas.height;
          var backCtx = backCanvas.getContext('2d');
          backCtx.drawImage(canvas, 0,0);

          ctx.clearRect(0, 0, canvas.width, canvas.height);
        
      
        //  ctx.globalCompositeOperation = 'destination-over';
        //   ctx.drawImage(GoldBackground, 0, data.getBoundingClientRect().height);

        //   ctx.drawImage(backCanvas, 0,0);

         // ctx.globalCompositeOperation = 'source-over';
         const msgWidth = 900;//465;
         const msgHeight = 80;//55;
         const msgRatio =msgHeight/ msgWidth;
         const halfWidth = msgWidth/2;
         const halfHeight = msgHeight/2;

         const dataWidth = (data.getBoundingClientRect().width + (imageBorder*2) + 10);

         const deviceRatioWidth = msgWidth * scale;
         const deviceRatioHeight = msgHeight * scale;
         
       //  backCtx.drawImage(wpbText, scale * ((data.getBoundingClientRect().width + (imageBorder*2) + 10)/2 - halfWidth), scale * (data.getBoundingClientRect().height+ (imageBorder*2)  - halfHeight));
       
      //   backCtx.drawImage(wpbText, 0, 0, (canvas.width+10)*msgRatio, canvas.height * msgRatio);

         backCtx.drawImage(wpbText, 
           0, //xpos
           //(scale * (dataWidth - msgWidth))/2,
         //  (canvas.width - deviceRatioWidth) + canvas.width/2 - msgWidth/4 ,
           canvas.height - (canvas.width * msgRatio) , //ypos
           canvas.width,
           canvas.width * msgRatio
          // data.getBoundingClientRect().width * scale, //width
        //   (data.getBoundingClientRect().width * scale) * msgRatio //height
           );
       
         backCtx.strokeStyle = 'white';
         backCtx.rect(imageBorder*scale, imageBorder*scale , data.getBoundingClientRect().width *scale, data.getBoundingClientRect().height * scale);
         backCtx.stroke();

         backCtx.globalCompositeOperation = 'destination-over';
         backCtx.drawImage(GoldBackground, 0, 0, canvas.width+10, canvas.height);


         const resizedCanvas = document.createElement("canvas");
        const resizedContext = resizedCanvas.getContext("2d");

        resizedCanvas.width = canvas.width/2;
        resizedCanvas.height = canvas.height/2;

   
        resizedContext.drawImage(backCanvas, 0, 0, canvas.width/2, canvas.height/2);
        const image = resizedCanvas.toDataURL('image/jpeg', 0.85)
          
           
     
           // var image = canvas.toDataURL('image/png', 1.0)
          // var image = canvas.toDataURL('image/jpg', 1.0)
        //   var image = backCanvas.toDataURL('image/jpg', 0.85)
           
           return image
         })
         .then((image) => {
           saveAs(image, 'WayPastBooks-drawing.jpg');

           const rand = Math.floor( Math.random() * doneMessageArr.length );
        //   alert(rand)

          // playSocute();
          if (doneMessageArr[rand] === 'socute'){
            playSocute();
          }else if (doneMessageArr[rand]  === 'cute'){
            playCute();
          } else if (doneMessageArr[rand]  === 'ruff'){
            playRuff();
          }else if (doneMessageArr[rand]  === 'puppies'){
            playPuppies();
          }else if (doneMessageArr[rand]  === 'everyone'){
            playEveryone();
          }

    
           
         //  setTakePic(false)
        // setSnapActive(false);
           
          // document.getElementById('threeCanvas').getContext('webgl' , {preserveDrawingBuffer: false});
          // document.getElementById('threeCanvas').children[0].getContext('webgl' , {preserveDrawingBuffer: false});
     
         //  document.querySelector('canvas').getContext('webgl', { preserveDrawingBuffer: false });
         //  html.style.width = null
         //  body.style.width = null
         })
         
     }
     
    const saveAs = (blob, fileName) => {

      if (!isMobile){
       var elem = window.document.createElement('a')
       elem.href = blob
       elem.download = fileName
       // elem.style = 'display:none;'
       ;(document.body || document.documentElement).appendChild(elem)
       if (typeof elem.click === 'function') {
         elem.click()
       } else {
         elem.target = '_blank'
         elem.dispatchEvent(
           new MouseEvent('click', {
             view: window,
             bubbles: true,
             cancelable: true,
           })
         )
       }
       URL.revokeObjectURL(elem.href)
       elem.remove()
      }else{

        fetch(blob)
        .then(res => res.blob())
        .then(blob => {
         // alert('got it')
          const file = new File([blob], 'WayPastBooks-DrawYourOwnDogs.jpg', {type: "image/jpeg" })

          const filesArray = [file]
          const shareData = {
            files: filesArray,
          };
          navigator.share(shareData).then(() => {
            console.log('Shared successfully');
          })
        })

        /*


        const share = async function(blob, fileName) {
          try {
           // const response = await fetch(shareimg);
           // const blob = await response.blob();
            const file = new File([blob], 'WayPastBooks-DrawYourOwnDogs.jpg', {type: "image/jpeg"});

            const filesArray = [file]
            const shareData = {
              files: filesArray,
            };
      
            await navigator.share(shareData).then(() => {
              console.log('Shared successfully');
            })
          } catch (err) {
            console.log(err.name, err.message);
          }
        };

        */
   
/*
        console.log(navigator)
        alert('mobile')

        const file = new File([blob], "WayPastBooks-DrawYourOwnDogs.jpg",{ type: "image/jpeg" })
        console.log('file is', file)

        const filesArray = [file]
        const shareData = {
          files: filesArray,
        };
        await navigator.share(shareData).then(() => {
          console.log('Shared successfully');
        }).catch(err => {
          console.error('Error:', err);
        });
*/
      }
    }

  ///////////////////////

  const nextBkgd = () => {
    if (bkgdSeen) clearInterval(intervalRef.current); 
    const limit = (counter + 1) % backgroundArr.length
    document.body.classList.remove('bg-tag');
    setCounter(limit)
  }

  const prevBkgd = () => {
    console.log('interval', intervalRef.current)
    if (bkgdSeen) clearInterval(intervalRef.current); 
    const limit = counter === 0 ? backgroundArr.length - 1 : counter - 1

    setCounter(limit)
  }
 

  const createButton = (
    label: string,
    handler: () => void,
    themeColor: string
  ) => (
    <button
      key={label}
      className={`btn btn-${themeColor} btn-block`}
      type="button"
      onClick={handler}
    >
      {label}
    </button>
  );

  const buttonsWithHandlers= [
    ['Undo', undoHandler, 'primary'],
    ['Redo', redoHandler, 'primary'],
    ['Clear All', clearHandler, 'primary'],
  //  ['Reset All', resetCanvasHandler, 'primary'],
    ['Pen', penHandler, 'secondary'],
    ['Eraser', eraserHandler, 'secondary'],
  //  ['Export Image', imageExportHandler, 'success'],
   // ['Export SVG', svgExportHandler, 'success'],
   // ['Get Sketching time', getSketchingTimeHandler, 'success'],
  ];

  const onChange = (updatedPaths: CanvasPath[]): void => {
    setPaths(updatedPaths);
  };

  const GoldBackground = new Image();
 // GoldBackground.src = '/assets/backgrounds/gold-frame.png';
  GoldBackground.src = '/assets/backgrounds/gold-bkgd.jpg';

  const wpbText = new Image();
  // GoldBackground.src = '/assets/backgrounds/gold-frame.png';
   wpbText.src = '/assets/images/wpbtext-findfun-small.png';

  return (
    <>
   {/* <AudioProvider /> */}
    <main className="container-fluid p-4">
      <a href="https://waypastbooks.com">
        <LogoBug showBug={true} currentColor={'#6adcc0'}/>
      </a>
      <header className="border-bottom d-flex align-items-center main-header">
        
        
        <img
          className="logo"
          src="/assets/images/board.jpg"
          alt=""
          width="150"
        />
        <div className="d-flex align-items-center title-col">
            <img 
            className="wpb-game"
            src="/assets/images/wpb-game-rect.png"
            alt=""
            width="150"
            />
              <h1 className="book">Way Past Jealous</h1>
              <h1 className="title">Draw Your Own Dogs</h1>
              <h3 >Step-By-Step</h3>
        </div>
        <img
          className="star"
          src="/assets/images/star.png"
          alt=""
          width="160"
        />
      </header>
      <div className="row main-content">
      
        <aside className="col-3 d-flex align-items-center guide-row">
          <div>
            <h3>Step 1</h3>
            <img src={StepOne} alt="icon" />
          </div>
          <div>
            <h3>Step 2</h3>
            <img src={StepTwo} alt="icon" />
          </div>
          <div>
            <h3>Step 3</h3>
            <img src={StepThree} alt="icon" />
          </div>
          <div>
            <h3>Step 4</h3>
            <img src={StepFour} alt="icon" />
          </div>
          <div>
            <h3>Step 5</h3>
            <img src={StepFive} alt="icon" />
          </div>
          <div className="spacer"></div>
          
         
        </aside>

  
        <section className="col-6 draw-section" >
     
          <section className="row no-gutters canvas-area m-0 p-0"
          onClick={() => {
            if (gameState === 'intro') getStarted();
          }
          }
          >

          <button 
          className="sound-button"
          onMouseEnter={playClickSFX}
          onClick={() => 
            setAudioState(!audioState)
          }
          >
            {audioState ?
             <HiSpeakerWave />
             :
             <HiSpeakerXMark />
            }
            
            </button>
            {/*
            <button className="arrow-btn left" onClick={prevBkgd}></button>
            <button className="arrow-btn" onClick={nextBkgd}></button>
  */}
           


            <div id="exportContainer" className="col-12 canvas p-0" 
            
            style={{  
              height: '500px',
            //  backgroundImage: `url('/assets/backgrounds/gold-frame.png')`
            //  backgroundImage:`url(${backgroundArr[0].url})`,
            }} >
             
           
              <BackgroundPanel 
                    backgroundArr={backgroundArr}
                    counter={counter}
                    
                  />
    
              <ReactSketchCanvas
                ref={canvasRef}
                onChange={onChange}
                
                onStroke={(stroke, isEraser) =>
                  setLastStroke({ stroke, isEraser })
                }
                {...canvasProps}

                
       
              />

              <div className="intro-text">
                  <div className="trails-main" onClick={() => set(state => !state)}>
                    <div>
                      {trail.map(({ x, height, ...rest }, index) => (
                        <a.div
                          key={introPhrase[index]}
                          className="trails-text"
                          style={{
                            ...rest,
                            transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                          }}
                        >
                          <a.div style={{ height }}>{introPhrase[index]}</a.div>
                         
                        </a.div>
                      ))}
                    </div>
                    
                  </div>
                  {(gameState === 'intro') &&
                    <div className="click-start">Click to get started</div>
                  }
                {/* <h2>Choose a background, draw and save your picture.</h2> */}
              </div>

               

              
           
            </div>
           
            <div >
                <ControlBar 
                  btnSound={activateClick}
                  exportAsPicture={grabImageData}
                  //exportAsPicture={imageExportHandler}
                  color={canvasProps.strokeColor} 
                  sliderState={sliderState}
                 // setColor={setColor} 
                  goUndo={undoHandler}
                  goRedo={redoHandler}
                  nextBkgd={nextBkgd}
                  prevBkgd={prevBkgd}
                  setColor={(e) => {

                    console.log(canvasProps.paintMode, (canvasProps.paintMode) ? e +'E6': e)
                    setCanvasProps(
                      (prevCanvasProps) => ({
                        ...prevCanvasProps,
                        strokeColor: (canvasProps.paintMode) ? e +'E6': e,
                      })
                    );
                  }}
                  clearDrawing={clearHandler}
                  value={canvasProps.strokeWidth} 
                 // sliderChange={handleChange}
                  sliderChange={(e) => {
                  // console.log(e)
                  setCanvasProps(
                    (prevCanvasProps) => ({
                      ...prevCanvasProps,
                      strokeWidth: e.target.value,
                      eraserWidth: e.target.value,
                    })
                  );
                }}
                  usePencil={penHandler}
                  useEraser={eraserHandler}
                  useBrush={brushHandler}
                 // openX={open}
                 // setOpen={setOpen}
                />

                {/*
                <InputField
                key={'strokeWidth'}
                fieldName={'strokeWidth'}
                type={'number'}
                canvasProps={canvasProps}
                setCanvasProps={setCanvasProps}
              />
                */}
              
                
           
              {/*
              <div className="d-grid gap-2">
                {buttonsWithHandlers.map(([label, handler, themeColor]) =>
                  createButton(label, handler, themeColor)
                )}
              </div>
                */}


                <MenuBar 
                  backgroundMode={changeToBackground}
                  btnSound={activateClick}
                  audioState={audioState}
         

                 // bkgdMsg={playChangebkgd}
                //  drawMsg={playClicktodraw}
                //  saveMsg={playTest}
                 // restartMsg={playClicktoplayagain}
                 /*
                  bkgdMsg={() => {
                    changeAudio(audioMap.changebkgd)
                   }
                  }
                  drawMsg={() => {
                    changeAudio(audioMap.clicktodraw)
                   }
                  }
                  saveMsg={() => {
                    //changeAudio(audioMap.save)
                    againSound.stop();
                    playSave();
                    
                   }
                  }
                  restartMsg={() => {
                    saveSound.stop();
                    playClicktoplayagain();
                   // changeAudio(audioMap.clicktoplayagain)
                   }
                  }
                  */

                  drawingMode={changeToDrawing}
                  exportAsPicture={grabImageData}
                  reboot={startOver}
               //   help={help}
                 
                  />
            </div>
          </section>

          
{/*
          <section className="row image-export p-3 justify-content-center align-items-start">
            <div className="col-5 row form-group">
              <label className="col-12" htmlFor="imageDataURI">
                Exported Data URI for imagetype
              </label>
              <textarea
                id="imageDataURI"
                className="dataURICode col-12"
                readOnly
                rows={10}
                value={dataURI || 'Click on export image'}
              />
            </div>
            <div className="col-5 offset-2">
              <p>Exported image</p>
              <img
                className="exported-image"
                id="exported-image"
                src={
                  dataURI ||
                  'https://via.placeholder.com/500x250/000000/FFFFFF/?text=Click on export image'
                }
                alt="exported"
              />
            </div>
          </section>
              */}

          
            
        </section>
        <section>
          <div className="row book-feature">
        
            <aside className="col-12 col-md-4 col-sm-3 d-flex align-items-center">
                <img src="/assets/images/jealous-angle-left.png" />
            </aside>
            <div className="col-12 col-md-8 col-sm-9">
            <ul>
                <li>
                  <span>Author:</span> Hallee Adelman
                </li>
                <li>
                  <span>Illustrator:</span> Karen Wall
                </li>
                <li>
                  <span>Publisher:</span> Albert Whitman
                </li>
                <li>
                  Part of the Great Big Feelings Series at <a href="https://waypastbooks.com">WayPastBooks.com</a>
                </li>
              </ul>
            </div>
             

            </div>
        </section>
      </div>
    </main>
    </>
  );
}

export default App;