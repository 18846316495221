import React, { useEffect, useState, useRef } from 'react'
//import { render } from 'react-dom'
import { PopoverPicker } from "../components/PopoverPicker";
import Slider from '@mui/material/Slider';
import { FaBeer } from 'react-icons/fa';
import { BsFillCameraFill, BsBrush} from "react-icons/bs";
import { GrEdit, GrErase } from "react-icons/gr";
import { MdDelete, MdDownload } from "react-icons/md";

import useSound from 'use-sound';



import { FaRegImage, FaUndo } from "react-icons/fa";

import { AiOutlineUndo, AiOutlineRedo } from "react-icons/ai";

import { useMediaQuery } from 'react-responsive'

import { 
IoArrowRedo,
IoArrowUndo,
IoArrowUndoOutline
} from "react-icons/io5";

import audioMap from '../resources/audio';

const maxAudio = 4;

const BkgdButton = ({action, hoverSound, audioState}) => {
  const [play, { sound }] = useSound(audioMap.changebkgd);
  const [gotMessage, setGotMessage] = useState(0);


  return (
    <button 
            onClick={action}
            onMouseEnter={() => {
              hoverSound();
              
              if ((gotMessage < maxAudio) && audioState){
                setGotMessage(gotMessage+1);
                play();
              }
            }}
            onMouseLeave={() => sound.stop()}
             >
              <FaRegImage  />
    </button>

  );
};



const DrawButton = ({action, hoverSound, audioState}) => {
  const [play, { sound }] = useSound(audioMap.clicktodraw);
  const [gotMessage, setGotMessage] = useState(0);


  return (
    <button 
            onClick={action}
            onMouseEnter={() => {
              hoverSound();
              if ((gotMessage < maxAudio) && audioState){
                setGotMessage(gotMessage+1);
                play();
              }
            }}
            onMouseLeave={() => sound.stop()}
             >
              <GrEdit />
    </button>

  );
};

const SaveButton = ({action, hoverSound, audioState}) => {
  const [play, { sound }] = useSound(audioMap.save);
  const [gotMessage, setGotMessage] = useState(0);

  return (
    <button 
            onClick={action}
            onMouseEnter={() => {
              hoverSound();
              if ((gotMessage < maxAudio) && audioState){
                setGotMessage(gotMessage+1);
                play();
              }
            }}
            onMouseLeave={() => sound.stop()}
             >
              <MdDownload />
    </button>

  );
};

const RestartButton = ({action, hoverSound, audioState}) => {
  const [play, { sound }] = useSound(audioMap.clicktoplayagain);
  const [gotMessage, setGotMessage] = useState(0);

  return (
    <button 
            onClick={action}
            onMouseEnter={() => {
              hoverSound();
              if ((gotMessage < maxAudio) && audioState){
                setGotMessage(gotMessage+1);
                play();
              }
            }}
            onMouseLeave={() => sound.stop()}
            >
              <IoArrowUndoOutline />
    </button>

  );
};


export default function MenuBar({
    btnSound,
    audioState,
    bkgdMsg,
    drawMsg,
    saveMsg,
    restartMsg,
    backgroundMode,
    drawingMode,
    exportAsPicture,
    reboot,
    help,
    showBug=true
}) {
  const [open, set] = useState(showBug)

  const isMobile = useMediaQuery({ query: '(max-width: 820px)' })

  useEffect(() => {

  }, [])



  return (
  
    <div className="MenuBar" >
      
            {/*
            <button 
            onClick={backgroundMode}
    
            onMouseEnter={() => {
               btnSound();
               bkgdMsg();
             }
            }
         
            
            ><FaRegImage  /></button>

            */}

<BkgdButton  action={backgroundMode} hoverSound={btnSound} audioState={audioState}/>
{/*
            <button 
            onClick={drawingMode}
            onMouseEnter={() => {
              btnSound();
              drawMsg();
            }
           }
            ><GrEdit /></button>
          */}
<DrawButton  action={drawingMode} hoverSound={btnSound} audioState={audioState}/>
     {/*
            <button 
            onClick={exportAsPicture}
            onMouseEnter={() => {
              btnSound();
              saveMsg();
            }
           }
            ><MdDownload /></button>
*/}
<SaveButton  action={exportAsPicture} hoverSound={btnSound} audioState={audioState}/>

            <RestartButton  action={reboot} hoverSound={btnSound} audioState={audioState}/>
            {/*
            <button 
            onClick={reboot}
            onMouseEnter={() => {
              btnSound();
              restartMsg();
            }
           }
            ><IoArrowUndoOutline /></button>
          */}
            
   
     
      
    </div>

  )
}